<template>
  <div class="pc-home">
    <pc-header :hideMenu="hideMenu"></pc-header>
    <div class="pc-main">
      <router-view></router-view>
    </div>
    <footer-bar></footer-bar>
    <shopping-car v-if="!hideCar"></shopping-car>
  </div>
</template>
<script>
import pcHeader from '@/components/pcHeader'
import footerBar from '@/components/footer'
import shoppingCar from '@/components/shoppingCar'
export default {
  name: 'home',
  components: {
    pcHeader,
    footerBar,
    shoppingCar
  },
  data() {
    return {
      hideMenu: false,
      isScroll: false,
      hideBak: false,
      timer: ''
    }
  },
  computed: {
    hideCar() {
      return this.$store.state.hideCar
    }
  },
  watch: {},
  mounted() {
    document.body.addEventListener('scroll', this.pageScroll)
  },
  beforeDestroy() {
    document.body.removeEventListener('scroll', this.pageScroll)
  },
  methods: {
    pageScroll(e) {
      let top = e.target.scrollTop
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (top > 5) {
          this.hideMenu = true
        } else {
          this.hideMenu = false
        }
      }, 100)
      if (top > 100) {
        this.hideMenu = true
      }
    }
  }
}
</script>