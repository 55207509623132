<template>
  <div class="shopping-car-modal">
    <div class="car flex-center" @click="showDialog = true">
      <div class="flex-center">
        <i class="el-icon-shopping-cart-full"></i>
        {{ allGoodsList.length }}
        {{ $t("wu-pin") }}
      </div>
      <div>
        <el-button size="mini">${{ totalMoney }}</el-button>
      </div>
    </div>

    <el-drawer
      :visible.sync="showDialog"
      direction="rtl"
      class="shopping-car-drawer"
    >
      <template slot="title">
        <div class="title flex-center-start">
          <div>
            <i class="el-icon-shopping-cart-full"></i>
          </div>
          <div>
            <div class="name">{{ allGoodsList.length }} {{ $t("wu-pin") }}</div>
            <div class="label">{{ $t("wo-de-gou-wu-che") }}</div>
          </div>
        </div>
      </template>

      <div class="car-modal-main">
        <div class="shopping-list">
          <div class="shop" v-for="(shop, index) in shoppingList" :key="index">
            <div class="name flex-center-between">
              <div class="flex-center">
                <el-checkbox
                  v-model="shop.checked"
                  @change="changeShop(shop, index)"
                ></el-checkbox>
                <span class="ml-10">{{ shop.shopName }}</span>
              </div>
            </div>

            <div class="product-list">
              <div
                class="list flex-center"
                v-for="(goods, i) in shop.goodsList"
                :key="i"
              >
                <el-checkbox
                  v-model="goods.checked"
                  @change="changeGoods(goods, i, index)"
                ></el-checkbox>
                <div class="img">
                  <img :src="goods.BusinessProduct.MainImage" alt="" />
                </div>
                <div class="flex-1 info">
                  <div class="name">
                    {{
                      goods.BusinessProduct.ProductTranslation &&
                      goods.BusinessProduct.ProductTranslation[0]
                        ? goods.BusinessProduct.ProductTranslation[0].Name
                        : ''
                    }}
                  </div>
                  <div class="options">{{ goods.optionName }}</div>
                  <el-input-number
                    size="mini"
                    v-model="goods.Number"
                    :min="1"
                  ></el-input-number>
                </div>
                <div class="price">
                  ${{ goods.optionPrice || goods.BusinessProduct.SalePrice }}
                </div>
                <div class="action">
                  <i class="el-icon-delete" @click="removeGoods(goods)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="car-empty flex-column-center"
          v-if="shoppingList.length === 0"
        >
          <img :src="emptyUrl" alt="" />
          <div class="info">{{ $t("gou-wu-che-huan-mei-you-shang-pin") }}</div>
          <el-button class="btn">{{ $t("qu-gou-wu") }}</el-button>
        </div>
        <el-button class="bottom-btn" @click="submit"
          >{{ $t("xia-dan") }} ${{ totalMoney }}</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { carList, removeCar } from '@/api/user'
export default {
  data() {
    return {
      emptyUrl: require('@/assets/imgs/car-empty.jpg'),
      showDialog: false,
      shoppingList: [],
      chooseList: [],
      allGoodsList: []
    }
  },
  computed: {
    totalMoney() {
      return this.chooseList
        .reduce((total, product) => {
          return (
            total +
            (product.optionPrice || product.BusinessProduct.SalePrice) *
              product.Number
          )
        }, 0)
        .toFixed(2)
    },
    initCar() {
      return this.$store.state.initCar
    },
    isLogin() {
      return this.$store.state.isLogin
    }
  },
  watch: {
    initCar() {
      this.init()
    },
    isLogin(val) {
      if (val) {
        this.init()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (!this.isLogin) {
        return
      }
      carList().then((res) => {
        let list = res.data.Items
        let shoppingList = []
        this.allGoodsList = []
        this.chooseList = []
        list.forEach((v) => {
          let shop = v.BusinessProduct.ShopID
          let shopName = v.BusinessProduct.Shop.Name
          let optionPrice = v.BusinessProductSpecificationsRelation.ID
            ? v.BusinessProductSpecificationsRelation.Price
            : ''
          if (optionPrice > 0) {
            v.SalePrice = optionPrice
          }
          let optionName = ''
          if (v.ProductSpecifications && v.ProductSpecifications.length > 0) {
            optionName = v.ProductSpecifications.map((v) => {
              return v.Name + ':' + v.Value
            }).join(';')
          }
          let index = shoppingList.findIndex((v) => {
            return v.ShopID == shop
          })
          if (index > -1) {
            shoppingList[index].goodsList.push({
              ...v,
              optionName: optionName,
              optionPrice: optionPrice,
              checked: true
            })
          } else {
            let param = {
              ShopID: shop,
              shopName: shopName,
              checked: true,
              goodsList: [
                {
                  ...v,
                  optionName: optionName,
                  optionPrice: optionPrice,
                  checked: true
                }
              ]
            }
            shoppingList.push(param)
          }
        })
        this.shoppingList = shoppingList
        this.shoppingList.forEach((v) => {
          this.chooseList = this.chooseList.concat(v.goodsList)
        })
        this.allGoodsList = [].concat(this.chooseList)
      })
    },
    changeShop(data, i) {
      let checked = data.checked
      this.shoppingList[i].goodsList.forEach((v) => {
        v.checked = checked
      })
      this.$forceUpdate()
      this.resetChoose()
    },
    changeGoods(data, i, index) {
      let list = this.shoppingList[index].goodsList.filter((v) => {
        return v.checked
      })
      if (list.length === this.shoppingList[index].goodsList.length) {
        this.shoppingList[index].checked = true
      } else {
        this.shoppingList[index].checked = false
      }
      this.resetChoose()
    },
    resetChoose() {
      this.chooseList = []
      this.shoppingList.forEach((v) => {
        v.goodsList.forEach((val) => {
          if (val.checked) {
            this.chooseList.push(val)
          }
        })
      })
    },
    removeGoods(data) {
      removeCar({
        ID: data.ID
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('shan-chu-cheng-gong'))
          this.init()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    submit() {
      let orderData = this.shoppingList.map((v) => {
        return {
          Shop: v.ShopID,
          goodsList: v.goodsList.map((val) => {
            return {
              ...val.BusinessProduct,
              BusinessProductSpecificationsRelation:
                val.BusinessProductSpecificationsRelation,
              BusinessProductSpecificationsRelationID:
                val.BusinessProductSpecificationsRelationID,
              count: val.Number,
              optionName: val.optionName,
              optionPrice: val.optionPrice
            }
          })
        }
      })

      sessionStorage.setItem('orderData', JSON.stringify(orderData))
      this.$router.push({
        name: 'order'
      })
      this.showDialog = false
    }
  }
}
</script>